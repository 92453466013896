function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // iOS detection (iPhone, iPad, iPod)
  if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true;
  }

  // Android detection
  if (/android/i.test(userAgent)) {
    return true;
  }

  // Detect iPads running iOS 13 or later, which no longer use 'iPad' in the user agent
  if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
    return true;
  }

  return false;
}

export default isMobile;
