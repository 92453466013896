import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { styled } from '@mui/system';
import styles from "./MobileWarning.module.css";

const Logo = styled('img')(({ theme }) => ({
  maxWidth: '150px',
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100px',
  },
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(90deg, #FF6B6B, #FFD93D)', // Gradient colors
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)', // Subtle shadow for depth
  fontSize: { xs: '3.5rem', sm: '5rem' },
  animation: 'glow 1.5s ease-in-out infinite alternate', // Optional glowing effect
  '@keyframes glow': {
    '0%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' },
    '100%': { textShadow: '2px 2px 12px rgba(255, 107, 107, 0.8)' },
  },
}));

const MobileWarning = ({ onContinue }) => {
  return (
    <Container
      className={styles.bg}
      sx={{
        position: 'relative',
        backgroundImage: 'url(/bg1@3x.png)',
        color: '#f7f7f7', // Light font color for better contrast
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 3,
        textAlign: 'center',
        '&::before': { // Pseudo-element for the blur effect on the background image
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: 'inherit', // Use the same background image
          backgroundSize: 'cover', // Cover the entire area
          filter: 'blur(10px)', // Apply the blur effect
          zIndex: -1, // Ensure the content is above the blurred background
        }
      }}
    >
      {/* Top Section with Logo, Company Name, and Tagline */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: {xs: -2, sm: -2, md: 2},
        }}
      >
        {/* Logo and Name in a Row */}
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 1, // Spacing between name and tagline
            flexDirection: { xs: 'column', sm: 'row' }, // Stack logo and text on mobile
            marginLeft: {md: -9}
          }}
        >
          <Logo 
            src="/logo.webp" 
            alt="UniVibes Logo" 
            sx={{
              width : { xs: '6rem', sm: '8rem', md: '8.5rem' },
              height: { xs: '6rem', sm: '8rem', md: '8.5rem' }, // Responsive height
              marginTop: { xs: 0, sm: 1, md: 2},            // Responsive top margin
              marginBottom: { xs: 0, sm: 1, md: 1.7},         // Responsive bottom margin
            }} 
          />
          <GradientTypography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '3.5rem', sm: '5rem' }, // Smaller font size on mobile
              textAlign: 'center', // Center text on mobile
              marginTop: { xs: 0.3, sm: 0 },
            }}
          >
            UniVibes
          </GradientTypography>
        </Box>
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontSize: { xs: '1.8rem', sm: '3.5rem', md: '3.5rem' },
            fontWeight: 'bold',
            marginTop: {xs: 1, sm: 1, md: 7},
            textAlign: 'center',
            marginLeft : {md: '9rem'},
            marginRight : {md: '9rem'},
            color: '#fff', // White color for better contrast
          }}
        >
         Catch your university vibes
        </Typography>
      </Box>

      {/* Middle Section with Warning Message */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          flexGrow: 1,
          justifyContent: 'flex-start', // Align to top instead of center
          paddingX: { xs: 2, sm: 4 },
          marginTop: { xs: 5, sm: 4, md: 25 }, // Add more top margin to lift the section
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '1.16rem', sm: '2rem' },
            marginBottom: 2,
            color: '#ffeb3b', // Yellow color for the warning message
          }}
        >
          🚨 Hold up! You're on a mobile device.
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontSize: { xs: '1rem', sm: '1.2rem' },
            marginBottom: {xs: 2, sm: 2 , md: 5},
            marginLeft: {md: '14rem'},
            marginRight: {md: '14rem'},
            color: '#ffffff', // White color for text
          }}
        >
         Our site is crafted for the ultimate desktop experience, so you might run into a few snags. 
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{
            fontSize: { xs: '1rem', sm: '1.2rem' },
            marginBottom: 4,
            color: '#ffffff', // White color for text
          }}
        >
          But, if you're feeling adventurous today, hit the button below.
        </Typography>
        <Box>
          <Button
            variant="contained"
            className={styles.primaryButton}
            sx={{
              fontSize: { xs: '0.8rem', sm: '1rem' }, // Adjust button text size on mobile
              padding: { xs: '8px 16px', sm: '12px 24px' },
              backgroundColor: '#ff5722', // Orange color for the button
              '&:hover': {
                backgroundColor: '#e64a19', // Darker orange on hover
              },
            }}
            onClick={onContinue}
          >
            Continue to the Desktop Site
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default MobileWarning;
