import React, { useState } from 'react';
import { Stack, TextField, Button, Typography, Link, Box, Alert } from '@mui/material';
import styles from './Waitlist.module.css';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const WaitlistComponent = () => {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleSubmit = async () => {
        if (!email) {
            setError('Please enter an email address.');
            return;
        }

        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        setError(''); // Clear any existing errors
        setIsSubmitting(true);

        try {
            await addDoc(collection(db, 'emails'), {
                email,
                timestamp: new Date(),
            });
            setSuccess('Got your email! 🎉 We’ve locked it in our vault of secrets 🗝️.');
            setEmail('');

            // Set a timer to clear the success message after 3 seconds
            setTimeout(() => {
                setSuccess('');
            }, 3000);

        } catch (error) {
            console.error('Error adding document: ', error);
            setError('Oops! Something went wrong. Maybe try sending a carrier pigeon? 🕊️');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
            sx={{ width: '100%', padding: 2, textAlign: 'center' }}
        >
            <Stack
                direction="column"
                spacing={1}
                sx={{ width: '100%', maxWidth: '400px', }}
            >
                <TextField
                    hiddenLabel
                    size="small"
                    aria-label="Enter your email address"
                    placeholder="Your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        border: '1px solid rgb(214, 226, 235)',
                        transition: 'border-color 120ms ease-in',
                        fontFamily: 'Inter, sans-serif',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                    }}
                    InputProps={{
                        style: {
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '1rem',
                            fontWeight: '400',
                            color: 'rgba(19, 27, 32, 1)',
                            borderRadius: '10px',
                            border: 'none',
                        },
                    }}
                    className={styles.textFieldHoverEffect}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    sx={{
                        backgroundColor: 'rgb(9, 89, 170)',
                        backgroundImage: 'linear-gradient(rgb(10, 102, 194), rgb(6, 64, 121))',
                        height: '2.5rem',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 700,
                        letterSpacing: '0.05em',
                        borderRadius: '10px',
                    }}
                >
                    {isSubmitting ? 'Joining...' : 'Join Our Waitlist'}
                </Button>
            </Stack>
            <Typography
                variant="caption"
                sx={{ marginTop: 0, opacity: 0.8, padding: '0 1rem' }}
            >
                By clicking &quot;Join Our Waitlist&quot; you agree to our&nbsp;
                <Link href="/TermsOfService.pdf" color="primary">
                    Terms of Service
                </Link>
                .
            </Typography>
            {error && (
                <Box sx={{ zIndex: 1300, position: 'relative', width: '100%', maxWidth: '400px' }}>
                    <Alert severity="error">{error}</Alert>
                </Box>
            )}
            {success && (
                <Box sx={{ zIndex: 1300, position: 'relative', width: '100%', maxWidth: '400px' }}>
                    <Alert severity="success">{success}</Alert>
                </Box>
            )}
        </Box>
    );
};

export default WaitlistComponent;
