import { useState, useCallback } from "react";
import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Button,
  Link,
  Typography,
  Box
} from "@mui/material";
import PopUpMobile from "../components/PopUpMobile";
import PortalPopup from "../components/PortalPopup";
import styles from "./LandingPageWaitlistMobile.module.css";
import WaitlistComponent from "../components/Waitlist";
import { styled } from '@mui/system';

const LandingPageWaitlistMobile = () => {
  const [isPopUpMobileOpen, setPopUpMobileOpen] = useState(false);

  const openPopUpMobile = useCallback(() => {
    setPopUpMobileOpen(true);
  }, []);

  const closePopUpMobile = useCallback(() => {
    setPopUpMobileOpen(false);
  }, []);


const GradientTypography = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(90deg, #20B8CD, #FFD93D)', // Updated gradient colors
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)', // Subtle shadow for depth
  fontSize: { xs: '3.5rem', sm: '2rem' },
  animation: 'glow 1.5s ease-in-out infinite alternate', // Optional glowing effect
  '@keyframes glow': {
    '0%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' },
    '100%': { textShadow: '2px 2px 12px rgba(32, 184, 205, 0.8)' }, // Updated glow color
  },
}));

  return (
    <>
      <Box className={styles.landingPageWaitlistMobile}>
        <section className={styles.hero}>
          <Box className={styles.bg}
          
          sx={{
            overflow: 'hidden',
            position: 'relative',
          }}>
          <Box
          component="img"
          alt="Hero Image"
          src="/hero-mobile.png" // Replace with the actual image URL
          sx={{
            width: '100%',
            height: 'auto',
            justifyContent: 'center', // Centre horizontally
            alignItems: 'center', // Centre vertically
            borderRadius: '8px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 20, // Ensure it's above other content
            position: 'relative', // Make sure it's positioned
            marginTop: {xs: '40rem', sm: '40rem', md: '56rem', lg: '34rem', xl: '34rem'},
            objectFit: 'cover', // Cover the section area
            overflow: 'hidden', // Hide any overflowing content
          }}
        />
          </Box>
          <Box className={styles.univibesParent}>
          <GradientTypography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '3.5rem', sm: '5rem' }, // Smaller font size on mobile
              textAlign: 'center', // Center text on mobile
              marginTop: { xs: '4rem', sm: '4rem' },
              zIndex: 15, // Ensure it's in front of the decorative image but behind the hero image
              position: 'relative',
              fontFamily: 'Lato, sans-serif', // Font family
            }}
          >
            UniVibes
          </GradientTypography>
       
            <Box className={styles.frameParent}>
              <Box className={styles.catchYourUniversityVibesParent}>
                <b className={styles.catchYourUniversity}>
                  Catch your university vibes
                </b>
                <Box className={styles.discoverAllThe}>
                  Discover all the campus events, societies, and connections you
                  need—all in one place. UniVibes is your go-to app for
                  everything happening at your university.
                </Box>
              </Box>
              <WaitlistComponent />
            </Box>
          </Box>
          <Box className={styles.imgFrame}>
            <Box className={styles.frameGroup}>
              <Box className={styles.pathParent}>
                <img className={styles.pathIcon} alt="" src="/path2.svg" />
                <img className={styles.pathIcon} alt="" src="/path3.svg" />
              </Box>
              <Box 
                component="img"
                src="/polaroid@3x.png"
                
                sx = {{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: 1000, 
                    position: 'relative',

                }}
                
              />
              <Box className={styles.polaroidParent}>
                <img
                  className={styles.polaroidIcon}
                  alt=""
                  src="/polaroid5@2x.png"
                />
                <img
                  className={styles.polaroidIcon1}
                  alt=""
                  src="/polaroid6@2x.png"
                />
                <img
                  className={styles.polaroidIcon2}
                  alt=""
                  src="/polaroid7@2x.png"
                />
                <img
                  className={styles.polaroidIcon3}
                  alt=""
                  src="/polaroid8@2x.png"
                />
                <img
                  className={styles.polaroidIcon4}
                  alt=""
                  src="/polaroid9@2x.png"
                />
              </Box>
            </Box>
          </Box>
        </section>
        <Box className={styles.featureSection}>
          <Box className={styles.superchargeYourUniversityExParent}>
            <h2 className={styles.superchargeYourUniversity}>
              Supercharge Your University Experience
            </h2>
            <WaitlistComponent />
          </Box>
          <Box className={styles.frameContainer}>
            <Box className={styles.frameParent2}
                sx ={{
                  backgroundColor: '#F2FAF6',
                }}
              >
              <Box className={styles.neverMissOutOnTheVibeParent}>
                <b className={styles.neverMissOut}>
                  Never Miss Out on the Vibe
                </b>
                <Box className={styles.stayInThe}>
                  Stay in the loop with personalised event recommendations.
                </Box>
              </Box>
              <Box className={styles.imgFrame1}>
                <Box
                    component="img"
                    alt="Never Miss Out"
                    src="/never-miss-out-mobile.png"
                    sx={{
                      width: '100%', // Make the image take the full width of its container
                      height: 'auto', // Maintain the aspect ratio
                      maxWidth: '500px', // Set a maximum width
                      maxHeight: '300px', // Set a maximum height
                      objectFit: 'cover', // Ensure the image covers the container without stretching
                    }}
                />
              </Box>
            </Box>
            <Box className={styles.frameParent2}
                sx ={{
                  backgroundColor: '#FFF9EE',
                  
                }}
            >
              <Box className={styles.neverMissOutOnTheVibeParent}>
                <b className={styles.neverMissOut}>Get the Vibe Right</b>
                <Box className={styles.stayInThe}>
                  Attend events that perfectly match your preference.
                </Box>
              </Box>
              <Box className={styles.imgFrame1}>
              <Box
                    component="img"
                    alt="Never Miss Out"
                    src="/get-vibe-right-mobile1.png"
                    sx={{
                      width: '100%', // Make the image take the full width of its container
                      height: 'auto', // Maintain the aspect ratio
                      maxWidth: '500px', // Set a maximum width
                      maxHeight: '300px', // Set a maximum height
                      objectFit: 'cover', // Ensure the image covers the container without stretching
                    }}
                />
              </Box>
            </Box>
            <Box className={styles.frameParent6}>
              <Box className={styles.neverMissOutOnTheVibeParent}>
                <b className={styles.neverMissOut}>Keep the Vibe Going</b>
                <Box className={styles.stayInThe}>
                  Easily connect with new friends and find event buddies
                </Box>
              </Box>
              <Box className={styles.imgFrame1}>
              <Box
                    component="img"
                    alt="Never Miss Out"
                    src="/keep-vibe-going-mobile.png"
                    sx={{
                      width: '100%', // Make the image take the full width of its container
                      height: 'auto', // Maintain the aspect ratio
                      maxWidth: '500px', // Set a maximum width
                      maxHeight: '300px', // Set a maximum height
                      objectFit: 'cover', // Ensure the image covers the container without stretching
                    }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.featureSection1}>
          <Box className={styles.superchargeYourUniversityExParent}>
            <b className={styles.neverMissOut}>Empower Your Society's Events</b>
            <WaitlistComponent />
          </Box>
          <Box className={styles.frameParent7}>
            <Box className={styles.frameParent8}>
              <Box className={styles.folderWrapper}>
                <img className={styles.folderIcon} alt="" src="/folder.svg" />
              </Box>
              <b className={styles.neverMissOut}>Organise Events Seamlessly</b>
              <Box
                className={styles.trackSignUpsMake}
              >{`Track sign-ups, make real-time updates, and collect feedback all in one place! `}</Box>
            </Box>
            <Box className={styles.frameParent8}>
              <Box className={styles.folderWrapper}>
                <img className={styles.folderIcon} alt="" src="/ticket.svg" />
              </Box>
              <b className={styles.neverMissOut}>Build-in Ticketing</b>
              <Box
                className={styles.trackSignUpsMake}
              >{`Manage ticket sales and memberships directly within the app. No need for third-party platforms. `}</Box>
            </Box>
            <Box className={styles.frameParent8}>
              <Box className={styles.folderWrapper}>
                <img className={styles.folderIcon} alt="" src="/target.svg" />
              </Box>
              <b className={styles.neverMissOut}>Targeted Promotion</b>
              <Box className={styles.trackSignUpsMake}>
                Reach verified university students instantly, and you can also
                customise your society page to stand out!
              </Box>
            </Box>
          </Box>
          <Box className={styles.imgFrame4}>
            <Box className={styles.frame335Transformed1Parent}>
              <img
                className={styles.frame335Transformed1Icon}
                alt=""
                src="/frame-335transformed-11@2x.png"
              />
              <Box className={styles.groupGroup}>
                <img className={styles.groupIcon} alt="" src="/group1.svg" />
                <Box className={styles.ellipseParent1}>
                  <img
                    className={styles.frameChild4}
                    alt=""
                    src="/ellipse-101@2x.png"
                  />
                  <b className={styles.title1}>
                    “Can’t wait for the next one!”
                  </b>
                </Box>
              </Box>
              <Box className={styles.groupParent3}>
                <Box className={styles.frameParent11}>
                  <Box className={styles.frameParent12}>
                    <Box className={styles.frameParent13}>
                      <Box className={styles.titleParent2}>
                        <b className={styles.title1}>104</b>
                        <img
                          className={styles.trendupIcon}
                          alt=""
                          src="/trendup1.svg"
                        />
                      </Box>
                      <b className={styles.title16}>Tickets Sold</b>
                    </Box>
                    <Box className={styles.ellipseParent}>
                      <img
                        className={styles.frameChild5}
                        alt=""
                        src="/ellipse-55@2x.png"
                      />
                      <img
                        className={styles.frameChild6}
                        alt=""
                        src="/ellipse-63@2x.png"
                      />
                      <img
                        className={styles.frameChild7}
                        alt=""
                        src="/ellipse-73@2x.png"
                      />
                      <img
                        className={styles.frameChild8}
                        alt=""
                        src="/ellipse-81@2x.png"
                      />
                      <img
                        className={styles.frameChild9}
                        alt=""
                        src="/ellipse-91@2x.png"
                      />
                    </Box>
                  </Box>
                  <img
                    className={styles.emojiU1f929Icon}
                    alt=""
                    src="/emoji-u1f9291@2x.png"
                  />
                </Box>
                <Box className={styles.notificationNotice}>
                  <Box className={styles.frameParent14}>
                    <Box className={styles.bellParent}>
                      <img
                        className={styles.bellIcon}
                        alt=""
                        src="/bell1.svg"
                      />
                      <b className={styles.newEventDate}>New event date!</b>
                    </Box>
                    <img
                      className={styles.bellIcon}
                      alt=""
                      src="/chevron-right1.svg"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={styles.footer}>
          <Box className={styles.footerChild} />
          <Box className={styles.footerItem} />
          <Box className={styles.frameParent15}>
            <Box className={styles.catchYourUniversityVibesParent}>
              <b className={styles.catchYourUniversity}>
                UniVibes is launching soon!
              </b>
              <Box className={styles.dontMissOutjoin}>
                Don’t miss out—join the waitlist today and be among the first to
                catch the vibe!
              </Box>
              
            </Box>
            <WaitlistComponent />
          </Box>

          <Box className={styles.copyright2024}>
          Copyright © 2024 UniVibes &nbsp;•&nbsp; All Rights Reserved. &nbsp;•&nbsp; <Link color='primary' href="/PrivacyPolicy.pdf">
            Privacy Policy
          </Link>
        </Box>
        </Box>
      </Box>
      {isPopUpMobileOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopUpMobile}
        >
          <PopUpMobile onClose={closePopUpMobile} />
        </PortalPopup>
      )}
    </>
  );
};

export default LandingPageWaitlistMobile;
