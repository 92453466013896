import Page from "./Page";
import styles from "./LandingPageWaitlistDesktop.module.css";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import WaitlistComponent from "../components/Waitlist";
import Frame from "../components/Frame";
import ResponsiveGridComponent from "../components/FeatureForStudent";


const GradientTypography = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(90deg, #20B8CD, #FFD93D)', // Updated gradient colors
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)', // Subtle shadow for depth
  fontSize: { xs: '3.5rem', sm: '2rem' },
  animation: 'glow 1.5s ease-in-out infinite alternate', // Optional glowing effect
  '@keyframes glow': {
    '0%': { textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' },
    '100%': { textShadow: '2px 2px 12px rgba(32, 184, 205, 0.8)' }, // Updated glow color
  },
}));


const Logo = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '6rem',
  },
}));

const LandingPageWaitlistDesktop = () => {
    
  return (
    <Box className={styles.landingPageWaitlistDesktop}>
      <Box className={styles.hero}>
        <Box className={styles.bg}>
        <Box
          component="img"
          alt="Hero Image"
          src="/hero.png" // Replace with the actual image URL
          sx={{
            width: '100%',
            height: '25rem',
            justifyContent: 'center', // Centre horizontally
            alignItems: 'center', // Centre vertically
            borderRadius: '8px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 20, // Ensure it's above other content
            position: 'relative', // Make sure it's positioned
            marginTop: {md: '56rem', lg: '34rem', xl: '34rem'},
            objectFit: 'cover', // Cover the section area
          }}
        />
        </Box>
        
        <Box
          component="img"
          alt=""
          src="/group-13@2x.png"
          sx={{
            top: {md: '14rem', lg: '6.719rem'},
            left: {md: '51.8rem', lg: '55.3rem', xl: '70.0rem'},
            width: '19.1rem',
            height: '19.856rem',
            objectFit: 'contain',
            position: 'absolute',
            marginLeft: {md: '-12.8rem', lg: '-3.2rem'}
          }}
        />
        <Box className={styles.univibesParent}>
          <GradientTypography
            variant="h1"
            component="h2"
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '3.5rem', sm: '5rem' }, // Smaller font size on mobile
              textAlign: 'center', // Center text on mobile
              marginTop: { xs: 0.3, sm: 0 },
              zIndex: 15, // Ensure it's in front of the decorative image but behind the hero image
              position: 'relative',
              fontFamily: 'Lato, sans-serif', // Font family
            }}
          >
            UniVibes
          </GradientTypography>
       
     
          <Box className={styles.frameParent}
            sx ={{
              marginTop: {md: '4rem', lg: '-4rem'},
            }}
            >
            <Box className={styles.catchYourUniversityVibesParent}>
              <b className={styles.univibes}>Catch your university vibes</b>
              <Box className={styles.discoverAllThe}>
                Discover all the campus events, societies, and connections you
                need—all in one place. UniVibes is your go-to app for everything
                happening at your university.
              </Box>
            </Box>
            <WaitlistComponent />
          </Box>
        </Box>
      
      </Box>
      <Box className={styles.featureForStudent}

sx={{

width: '100vw', // Full viewport width

backgroundColor: '#fff', // White background

overflow: 'hidden',

display: 'flex',

flexDirection: 'column',

alignItems: 'center',

justifyContent: 'flex-start',

padding: '12vh 5%', // Vertical padding based on viewport height and horizontal padding in percentage

gap: '5%', // Gap between elements

textAlign: 'left',

color: '#333333', // Dark grey text

fontFamily: 'Lato, sans-serif', // Font family

  
  

}}>

<b className={styles.superchargeYourUniversity}>

Supercharge Your University Experience

</b>

<WaitlistComponent />

<Box className={styles.frameContainer}
    sx ={{
      marginTop: {md: '5rem', lg: '4rem'},
    }}
>

<Box className={styles.frameBox}
    sx =
    {{
      backgroundColor: '#F2FAF6',
      padding: {md: '3rem', lg: '2rem'},
      borderRadius: '10px',
   
    }}
>
<Box>

<b className={styles.univibes}>Never Miss Out on the Vibe</b>

<Box className={styles.stayInThe}>

Stay in the loop with personalised event recommendations.

</Box>

</Box>

<Box
  component="img"
  alt="Never Miss Out"
  src="/never-miss-out.png"
  sx={{
    width: '100%', // Make the image take the full width of its container
    height: 'auto', // Make the image take the full height of its container
    maxWidth: '500px', // Set a maximum width
    maxHeight: '300px',
    objectFit: 'cover' // Ensure the image covers the container without stretching
  }}
/>

</Box>

<Box className={styles.frameParent2}>

<Box className={styles.frameParent3}>

<Box>

<b className={styles.univibes}>Get the Vibe Right</b>

<Box className={styles.stayInThe}>

Attend events that perfectly match your preference.

</Box>

</Box>

<Box className={styles.groupContainer}>

<Box className={styles.groupBox}>

<Box className={styles.frameParent4}>

<Box className={styles.frameParent5}>

<Box className={styles.ellipseParent}>

<img

className={styles.frameItem}

alt=""

src="/ellipse-5@2x.png"

/>

<img

className={styles.frameInner}

alt=""

src="/ellipse-6@2x.png"

/>

<img

className={styles.ellipseIcon}

alt=""

src="/ellipse-7@2x.png"

/>

</Box>

<Box className={styles.title1}>

92% people rated it 5 stars~

</Box>

</Box>

<Box className={styles.frameParent6}>

<Box className={styles.ellipseParent}>

<img

className={styles.frameChild1}

alt=""

src="/ellipse-51@2x.png"

/>

</Box>

<Box className={styles.title1}>

"The host is very patient."

</Box>

</Box>

<Box className={styles.frameParent7}>

<Box className={styles.emojiFireParent}>

<img

className={styles.emojiFireIcon}

alt=""

src="/-emoji-fire.svg"

/>

<Box className={styles.ellipseBox} />

</Box>

<Box className={styles.title1}>

Only a few tickets left!

</Box>

</Box>

</Box>

<img

className={styles.groupChild}

alt=""

src="/group-39920.svg"

/>

</Box>

</Box>

</Box>

<Box className={styles.frameParent8}>

<Box>

<b className={styles.univibes}>Keep the Vibe Going</b>

<Box className={styles.stayInThe}>

Easily connect with new friends and find event buddies.

</Box>

</Box>

<Box className={styles.groupFrame}>

<Box className={styles.groupParent1}>

<Box className={styles.groupParent2}>

<img

className={styles.groupItem}

alt=""

src="/group-8@2x.png"

/>

<Box className={styles.ellipseGroup}>

<img

className={styles.frameChild2}

alt=""

src="/ellipse-4@2x.png"

/>

<Box className={styles.titleContainer}>

<b className={styles.title}>Cate Blanc</b>

<Box className={styles.title8}>Psychology</Box>

</Box>

</Box>

<Box className={styles.ellipseContainer}>

<img

className={styles.frameChild2}

alt=""

src="/ellipse-41@2x.png"

/>

<Box className={styles.titleContainer}>

<b className={styles.title}>Lila Summers</b>

<Box className={styles.title8}>Computer Science</Box>

</Box>

</Box>

</Box>

<Box className={styles.buttonParent}>

<Box className={styles.button1}>

<b className={styles.title1}>🥂</b>

<Box className={styles.label3}>12 common groups</Box>

</Box>

<Box className={styles.button2}>

<b className={styles.title1}>🎓</b>

<Box className={styles.label3}>Both in 3rd year</Box>

</Box>

<Box className={styles.button3}>

<b className={styles.title1}>🎊</b>

<Box className={styles.label3}>

Rated 4 events the same

</Box>

</Box>

</Box>

</Box>

</Box>

</Box>

</Box>

</Box>

</Box>

<Box className={styles.empowerYourSocietysEventsParent}

>

<b className={styles.empowerYourSocietys}>

Empower Your Society's Events

</b>

<WaitlistComponent / >

<Box className={styles.frameParent9}
 sx ={{
  padding: {md:  '0 3rem 0 3rem', lg: '0 5rem 0 5rem'},
}}

>
<Box className={styles.frameParent10}>

<Box className={styles.folderWrapper}>

<img className={styles.folderIcon} alt="" src="/folder.svg" />

</Box>

<b className={styles.univibes}>Organise Events Seamlessly</b>

<Box

className={styles.stayInThe}

>{`Track sign-ups, make real-time updates, and collect feedback all in one place! `}</Box>

</Box>

<Box className={styles.frameParent10}>

<Box className={styles.folderWrapper}>

<img className={styles.folderIcon} alt="" src="/ticket.svg" />

</Box>

<b className={styles.univibes}>Build-in Ticketing</b>

<Box

className={styles.stayInThe}

>{`Manage ticket sales and memberships directly within the app. No need for third-party platforms. `}</Box>

</Box>

<Box className={styles.frameParent10}>

<Box className={styles.folderWrapper}>

<img className={styles.folderIcon} alt="" src="/target.svg" />

</Box>

<b className={styles.univibes}>Targeted Promotion</b>

<Box className={styles.stayInThe}>

Reach verified university students instantly, and you can also

customise your society page to stand out!

</Box>

</Box>

</Box>

<Box className={styles.groupWrapper1}>

<Box className={styles.frame335Transformed1Parent}>

<img

className={styles.frame335Transformed1Icon}

alt=""

src="/frame-335transformed-1@2x.png"

/>

<Box className={styles.groupGroup}>

<img className={styles.groupIcon} alt="" src="/group.svg" />

<Box className={styles.ellipseParent1}>

<img

className={styles.frameChild4}

alt=""

src="/ellipse-10@2x.png"

/>

<Box className={styles.title1}>

"Can't wait for the next one!"

</Box>

</Box>

</Box>

<Box className={styles.groupParent3}>

<Box className={styles.frameParent13}>

<Box className={styles.frameParent14}>

<Box className={styles.frameParent15}>

<Box className={styles.titleParent2}>

<b className={styles.title1}>104</b>

<img

className={styles.trendupIcon}

alt=""

src="/trendup.svg"

/>

</Box>

<b className={styles.title16}>Tickets Sold</b>

</Box>

<Box className={styles.ellipseParent}>

<img

className={styles.frameChild5}

alt=""

src="/ellipse-52@2x.png"

/>

<img

className={styles.frameChild6}

alt=""

src="/ellipse-61@2x.png"

/>

<img

className={styles.frameChild7}

alt=""

src="/ellipse-71@2x.png"

/>

<img

className={styles.frameChild8}

alt=""

src="/ellipse-8@2x.png"

/>

<img

className={styles.frameChild9}

alt=""

src="/ellipse-9@2x.png"

/>

</Box>

</Box>

<img

className={styles.emojiU1f929Icon}

alt=""

src="/emoji-u1f929@2x.png"

/>

</Box>

<Box className={styles.notificationNotice}>

<Box className={styles.frameParent16}>

<Box className={styles.bellParent}>

<img className={styles.bellIcon} alt="" src="/bell.svg" />

<b className={styles.newEventDate}>New event date!</b>

</Box>

<img

className={styles.bellIcon}

alt=""

src="/chevron-right.svg"

/>

</Box>

</Box>

</Box>

</Box>

</Box>

</Box>
        <Frame />

    </Box>

  );
};

export default LandingPageWaitlistDesktop;
