import PropTypes from "prop-types";
import styles from "./Page.module.css";

const Page = ({ className = "" }) => {
  return (
    <div className={[styles.page, className].join(" ")}>
      <header className={styles.frameParent}>
        <div className={styles.ellipseWrapper}>
          <div className={styles.frameChild} />
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.frameContainer}>
            <div className={styles.univibesIsLaunchingSoonParent}>
              <h1 className={styles.univibesIsLaunching}>
                UniVibes is launching soon!
              </h1>
              <div className={styles.dontMissOutjoin}>
                Don’t miss out—join the waitlist today and be among the first to
                catch the vibe!
              </div>
            </div>
            <div className={styles.textInputParent}>
              <TextField
                className={styles.textInput}
                placeholder="Enter your email address"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                  "& .MuiInputBase-root": {
                    height: "54px",
                    backgroundColor: "#eff1f5",
                    borderRadius: "12px",
                  },
                  "& .MuiInputBase-input": { color: "#888c8c" },
                }}
              />
              <Button
                className={styles.buttonDesktop}
                disableElevation
                variant="contained"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "16",
                  background: "#20b8cd",
                  borderRadius: "12px",
                  "&:hover": { background: "#20b8cd" },
                  width: 139,
                  height: 52,
                }}
              >
                Join Waitlist
              </Button>
            </div>
            <div className={styles.footerIcons}>
              <div className={styles.footerIconsChild} />
              <img
                className={styles.footerIconsItem}
                loading="lazy"
                alt=""
                src="/group-7@2x.png"
              />
            </div>
          </div>
          <div className={styles.copyright2024UnivibesAlWrapper}>
            <div className={styles.copyright2024}>
              Copyright © 2024 UniVibes - All Rights Reserved.
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

Page.propTypes = {
  className: PropTypes.string,
};

export default Page;
