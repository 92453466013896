import PropTypes from "prop-types";
import styles from "./Frame.module.css";
import WaitlistComponent from "./Waitlist";
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"

const Frame = () => {
  return (
    <Box 
    component="footer" 
    className={styles.frameParent}
    sx={{
      paddingTop: { sm: '3.75rem', md:  '3.75rem', lg:  '3.75rem', xl:  '3.75rem' },
      paddingRight: { sm: '3rem', md:  '0rem', lg:  '0rem', xl:  '0rem' },
      paddingLeft: { sm: '3rem', md:  '0rem', lg:  '0rem', xl:  '0rem' },
      borderTopLeftRadius: '3px', // Top left corner radius
      borderTopRightRadius: '3px', // Top right corner radius
      borderBottomLeftRadius: '0px', // Bottom left corner radius
      borderBottomRightRadius: '0px', // Bottom right corner radius
      

    }}
  >
      <div className={styles.frameGroup}>
        <div className={styles.frameContainer}>
          <div className={styles.univibesIsLaunchingSoonParent}>
            <b className={styles.univibesIsLaunching}>
              UniVibes is launching soon!
            </b>
            <div className={styles.dontMissOutjoin}>
              Don’t miss out—join the waitlist today and be among the first to
              catch the vibe!
            </div>
          </div>
          <WaitlistComponent />
        </div>
        <div className={styles.copyright2024}>
          Copyright © 2024 UniVibes &nbsp;•&nbsp; All Rights Reserved. &nbsp;•&nbsp; <Link color='primary' href="/PrivacyPolicy.pdf">
            Privacy Policy
          </Link>
        </div>
        <div className={styles.frameChild} />
        <div className={styles.frameItem} />

      </div>
    </Box>
  );
};

Frame.propTypes = {
  className: PropTypes.string,
};

export default Frame;