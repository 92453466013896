import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigationType, useLocation } from "react-router-dom";
import LandingPageWaitlistDesktop from "./pages/LandingPageWaitlistDesktop";
import LandingPageWaitlistMobile from "./pages/LandingPageWaitlistMobile";
import isMobile from './utils/isMobile';
import MobileWarning from "./components/MobileWarning";

function App() {
  const [showWarning, setShowWarning] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(isMobile());
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  // Scroll to the top of the page when navigation occurs
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  // Update document title and meta description based on the current path
  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "UniVibes";
        metaDescription = "Join our waitlist for the upcoming launch.";
        break;
      // Add more cases here if you add more routes later
      default:
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  // Check if the user is on a mobile device and show the warning if so
  useEffect(() => {
    if (isMobile()) {
      setShowWarning(true);
    }

    const handleResize = () => {
      setIsMobileDevice(isMobile());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle dismissing the mobile warning
  const handleContinue = () => {
    setShowWarning(false);
  };

  return (
    <div>
        <Routes>
            <Route path="/" element={isMobileDevice ? <LandingPageWaitlistMobile /> : <LandingPageWaitlistDesktop />} />   


        </Routes>
    </div>
  );
}

export default App;
