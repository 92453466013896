import PropTypes from "prop-types";
import styles from "./PopUpMobile.module.css";

const PopUpMobile = ({ className = "" }) => {
  return (
    <div className={[styles.popUpMobile, className].join(" ")}>
      <div className={styles.frameParent}>
        <div className={styles.frameGroup}>
          <div className={styles.emojiU1f973Parent}>
            <img
              className={styles.emojiU1f973Icon}
              alt=""
              src="/emoji-u1f973.svg"
            />
            <div className={styles.awesomeYoureIn}>Awesome! You’re in~</div>
          </div>
          <div className={styles.queueNoParent}>
            <b className={styles.queueNo}>Queue No.</b>
            <b className={styles.b}>86</b>
          </div>
        </div>
        <div className={styles.moveUpTheQueueBySharingUParent}>
          <div className={styles.moveUpThe}>
            Move up the queue by sharing UniVibes with your friends!
          </div>
          <div className={styles.buttonDesktop}>
            <b className={styles.label}>Share</b>
            <img
              className={styles.bookmarkAddIcon}
              alt=""
              src="/bookmark-add.svg"
            />
          </div>
        </div>
      </div>
      <img className={styles.xIcon} alt="" src="/x.svg" />
    </div>
  );
};

PopUpMobile.propTypes = {
  className: PropTypes.string,
};

export default PopUpMobile;
